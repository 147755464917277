import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getImageUrl } from 'takeshape-routing';
import slugify from 'slugify';

import { MapPin, ShoppingBag } from 'react-feather';
import { IconTakeOut, IconShipping, IconDineIn } from '../components/icons';

import Link from '../components/link';
import MoreCafes from '../components/MoreCafes';
import Layout from '../layouts/default';

export const Cafe = ({ cafe }) => {
  let ctaCopy = 'Support';
  let cafeName = cafe.name;
  if (cafe.sellingCoffee) {
    ctaCopy = 'Shop';
  }
  if (cafe.shortName !== null) {
    cafeName = cafe.shortName;
  }
  // Set Masthead Image
  let coverImg = 'https://placekitten.com/1400/800';
  if (cafe.pageImage !== null) {
    coverImg = getImageUrl(cafe.pageImage.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  } else if (cafe.coverImage !== null) {
    coverImg = getImageUrl(cafe.coverImage.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  }
  // Create Address Slug for GMap Search Please
  let addressSlug = null;
  if (cafe.location !== null) {
    addressSlug = slugify(`${cafe.name} + ${cafe.location}`, '+');
  }
  // Set Takeout Status
  let takeoutStatus = 'N/A';
  const takeoutStatusData = cafe?.operations?.takeOut?.openForTakeout || null;
  if (takeoutStatusData !== null) {
    takeoutStatus = takeoutStatusData;
    console.log(takeoutStatusData);
  }
  // Set Shipping Status
  let shippingStatus = 'N/A';
  const shippingStatusData = cafe?.operations?.shipping?.shippingStatus || null;
  if (shippingStatusData !== null) {
    shippingStatus = shippingStatusData;
  }
  // Set DineIn Status
  let dineInStatus = 'N/A';
  const dineInStatusData = cafe?.operations?.dineIn?.openDineIn || null;
  if (dineInStatusData !== null) {
    if (dineInStatusData) {
      dineInStatus = 'Yes';
    } else {
      dineInStatus = 'No';
    }
  }
  // Sidebar
  const CafeSidebar = () => (
    <aside className="cafe__infoBox">
      <section className="cafe__nameLocation">
        <h1 className="mb0">{cafe.name}</h1>
        <h3 className="h3--secondary">{cafe.shortLocation}</h3>
      </section>
      <section className="cafe__operations">
        <div className="takeOut operation">
          <IconTakeOut />
          <h5>Takeout</h5>
          <span className="operation__status">{takeoutStatus}</span>
        </div>
        <div className="shipping operation">
          <IconShipping />
          <h5>Shipping</h5>
          <span className="operation__status">{shippingStatus}</span>
        </div>
        <div className="dineIn operation">
          <IconDineIn />
          <h5>Dine-In</h5>
          <span className="operation__status">{dineInStatus}</span>
        </div>
      </section>
      {addressSlug ? (
        <section className="cafe__address">
          <MapPin />
          <a className="infoBox__location" href={`https://www.google.com/maps/search/?api=1&query=${addressSlug}`} target="_blank" rel="noreferrer">
            {cafe.location}
          </a>
        </section>
      ) : ''}
      <Link
        to={cafe.cafeUrl}
        target="_blank"
        className="btn btn--lg"
      >
        {ctaCopy}
        {' '}
        {cafeName}
      </Link>
    </aside>
  );

  return (
    <article className="cafeSingle">
      <div className="cafe__coverImage x rel">
        <img
          className="x y fill"
          alt={cafe.name}
          src={coverImg}
        />
      </div>
      <div className="cafe__content mt3">
        <section className="cafe__copy">
          <header className="cafe__nameLocation mb15">
            <h1 className="mb0">{cafe.name}</h1>
            <h3 className="h3--secondary">{cafe.shortLocation}</h3>
          </header>
          {cafe.about && (
            <div className="cafe__about">
              <h3>About {cafe.shortName}</h3>
              <div dangerouslySetInnerHTML={{ __html: cafe.about }} />
            </div>
          )}
          {cafe.status && (
          <div className="cafe__status">
            <h3>Status</h3>
            <div dangerouslySetInnerHTML={{ __html: cafe.status }} />
          </div>
          )}
          {cafe.howToHelp && (
          <div className="cafe__howToHelp">
            <h3>How You Can Help</h3>
            <div dangerouslySetInnerHTML={{ __html: cafe.howToHelp }} />
          </div>
          )}
        </section>
        <CafeSidebar />
      </div>
    </article>
  );
};

export default ({ data }) => {
  const cafe = data?.takeshape?.cafe || {};
  const otherCafes = data?.takeshape?.otherCafes?.items || {};
  return (
    <Layout>
      <Cafe cafe={cafe} />
      <MoreCafes cafes={otherCafes} size={5} desktopLimit={3} />
    </Layout>
  );
};

Cafe.propTypes = {
  cafe: PropTypes.objectOf(PropTypes.string).isRequired,
};

export const query = graphql`
  query($cafeId: ID!) {
    takeshape {
      cafe: getCafe(_id: $cafeId) {
        name
        cafeUrl
        coverImage {
          path
        }
        pageImage {
          path
        }
        location
        shortLocation
        shortName
        operations {
          shipping {
            shippingStatus
          }
          takeOut {
            openForTakeout
          }
          dineIn {
            openDineIn
          }
        }
        about: aboutHtml
        status: statusHtml
        howToHelp: howToHelpHtml
        sellingCoffee
      }
      otherCafes: getCafeList(
        where: {NOT: {_id: {eq: $cafeId }}}
        size: 250
      ) {
        items {
          name
          cafeUrl
          cardBio
          coverImage {
            path
          }
          location
          shortLocation
          shortName
          status: statusHtml
          howToHelp: howToHelpHtml
          sellingCoffee
        }
      }
    }
  }
`;
